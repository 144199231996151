import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../../components/hero'
import Layout from '../../components/layout'


class RootIndex extends React.Component {
    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const projects = get(this, 'props.data.allContentfulAchievements.edges')
        const [author] = get(this, 'props.data.allContentfulPerson.edges')

        return (
            <Layout location={this.props.location}>
                <div style={{ background: '#fff' }}>
                    <Helmet title={siteTitle} />
                    <Hero data={author.node} />
                    <div className="wrapper">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: author.node.bio.childMarkdownRemark.html,
                            }}
                        />

                        <h2 className="section-headline">主な実績</h2>
                        <section className="project">
                            {projects.map(({node}) => {
                                return(
                                    <div className={'project-item'} key={node.url}>
                                        <div className="flex column">
                                            <p className="project-date small">
                                                {node.date}</p>
                                        </div>
                                        <div className="flex align-center">
                                            <p className="project-title medium">
                                                {node.tournamentName}
                                            </p>
                                            <span className={'bold medium project-place'}>
                                                {node.place}
                                            </span>
                                            <span className="medium">位</span>
                                        </div>
                                        <div className="txt-box">
                                        </div>

                                    </div>
                                )
                            })}
                        </section>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default RootIndex

export const pageQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAchievements(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          tournamentName
          date(formatString: "YYYY.MM.DD")
          place 
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            childMarkdownRemark {
              html
            }          }
          bio {
            childMarkdownRemark {
              html
            }
          }           
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
